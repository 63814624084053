<template>
  <v-container class="mt-10">
    <v-row>
      <v-col cols="12" sm="2"></v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="firstName"
          label="First Name *"
          filled
        ></v-text-field>
        <v-text-field v-model="email" label="Email" filled></v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          v-model="lastName"
          label="Last Name *"
          filled
        ></v-text-field>
        <VuePhoneNumberInput
          class="mt-2"
          default-country-code="NG"
          v-model="unformatedphoneNumber"
          @update="results = $event"
          filled
          size="lg"
        />
      </v-col>
      <v-col cols="12" sm="2"></v-col>
    </v-row>
    <div class="text-center">
      <v-btn @click="AddRider()" rounded color="info" dark class="mt-5">
        Proceed
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  components: {
    VuePhoneNumberInput,
  },
  data: () => ({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    middleName: "",
    email: "",
    unformatedphoneNumber: "",
    results: null,
  }),
  methods: {
    AddRider() {
      if (
        this.phoneNumber == "" ||
        this.firstName == "" ||
        this.lastName == ""
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        token: this.getToken,
        firstName: this.firstName,
        phoneNumber: this.phoneNumber,
        lastName: this.lastName,
        email: this.email,
        route: this.$router,
      };
      this.$store.dispatch("AddRider", payload);
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    results(val) {
      if (val) {
        if (val.formattedNumber) {
          this.phoneNumber = val.formattedNumber;
        }
      }
    },
  },
};
</script>